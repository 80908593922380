@keyframes spinner {
  0%,
  100% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
}

.progressSpinner circle {
  animation-name: spinner;
  animation-duration: 680ms;
  animation-iteration-count: infinite;
  opacity: 0.1;
}

.progressSpinner circle:nth-child(1) {
  animation-delay: 80ms;
}

.progressSpinner circle:nth-child(2) {
  animation-delay: 160ms;
}

.progressSpinner circle:nth-child(3) {
  animation-delay: 240ms;
}

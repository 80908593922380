@custom-media --viewportNewPhoneMax (max-width: 767px);
@custom-media --viewportNewTabletMin (min-width: 768px);
@custom-media --viewportNewTabletMax (max-width: 1023px);
@custom-media --viewportNewTabletLandscapeMin (min-width: 1024px);
@custom-media --viewportNewTabletLandscapeMax (max-width: 1151px);
@custom-media --viewportNewDesktopMin (min-width: 1152px);
@custom-media --viewportNewDesktopMax (max-width: 1439px);
@custom-media --viewportNewLargeDesktopMin (min-width: 1440px);

:root {
  /* Colors */
  --colorPanelBackgroundBrown: hsla(36, 24%, 96%, 1);
  --colorPageBackgroundDefault: white;

  /* Typography */
  --fontFamilySerif: 'Canela Deck Web', georgia, serif;
  --fontFamilySans: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;

  /* Arbitrary layout sizes */
  --elementBorderRadius: 0.25rem;
}

@import url('https://rsms.me/inter/inter.css');
@import url('https://unpkg.com/modern-normalize@0.6.0/modern-normalize.css');

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: var(--rem);
  line-height: 1.5;
}

body {
  color: var(--colorTextDefault);
  font-size: 0.875rem;
  font-family: var(--fontFamilySans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Image resets */

img {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

/* Typography resets */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  margin-top: 0;
}

h1 {
  font-size: 1.6875rem;
  line-height: 1.4;
}

h2 {
  font-size: 1.625rem;
  line-height: 1.4;
}

h3 {
  font-size: 1.5rem;
  line-height: 2;
}

h1,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: 600;
}

small {
  font-size: 0.75rem;
  line-height: 1rem;
}

a {
  color: var(--colorAnchorText);
  transition: color 200ms ease;
  text-decoration: none;
}

a:hover {
  color: var(--colorAnchorTextHover);
}

code,
pre {
  font-family: var(--fontFamilyMono);
}

/* General use type classes */

/* Typography for marketing pages */
.headline,
.subhead,
.headline-secondary,
.subhead-secondary {
  margin: 0;
  font-family: var(--fontFamilySerif);
  font-weight: 500;
  line-height: 1.2;
}

.headline,
.subhead {
  font-size: 3rem; /* 48pt */
  letter-spacing: -0.025em;
}

@media (min-width: 768px) {
  .headline,
  .subhead {
    letter-spacing: -0.03em;
  }
}

.subhead {
  margin-bottom: 4rem;
  color: var(--colorTextMuted);
}

.headline-secondary,
.subhead-secondary {
  font-size: 2.625rem; /* 42pt */
  letter-spacing: -0.025em;
}

.subhead-secondary {
  margin-bottom: 4rem;
  color: var(--colorTextMuted);
}

.linklist {
  list-style: none;
  padding-left: 0;
}

/* Typographic Styles */
.typography-caption {
  margin-bottom: 1rem;
  color: var(--colorTextSemiMuted);
  font-family: var(--fontFamilySans);
  text-align: inherit;
}

.typography-nano {
  display: inline;
  text-align: inherit;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

/* Typography utils */
.colorTextDefault {
  color: var(--colorTextDefault);
}

.colorTextMuted {
  color: var(--colorTextMuted);
}

/* Responsive utils */
@media (max-width: 767px) {
  .hideForPhone {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .showForPhoneOnly {
    display: none !important;
  }
}

/* Swiper */
.swiper-pagination {
  bottom: 2rem !important;
}
.swiper-pagination-bullet {
  color: var(--colorTextVeryMuted);
  height: 4px !important;
  width: 4px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.swiper-pagination-bullet-active {
  background: black !important;
}
.pagination-modifier {
  left: 50%;
  transform: translate(-50%, -50%) !important;
  bottom: 0px !important;
}

.typeography_heading__xIyyV {
  font-family: var(--fontFamilySans);
  font-weight: 500;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}

.typeography_subheading__5aoAf {
  font-family: var(--fontFamilySans);
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}

.typeography_bodyText__7C7Ye {
  font-family: var(--fontFamilySans);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.typeography_smallText__jhfdP {
  font-family: var(--fontFamilySans);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

/******************************************************************************/
/* NEW TYPOGRAPHY CLASSES: */

.typeography_brand48__JDrwt {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.015em;
}
.typeography_brand40__xmwya {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.015em;
}
.typeography_brand32__JCgUy {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.015em;
}
.typeography_brand28__j4mhI {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}

.typeography_ui32SemiBold__PzQiM {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.96px;
}

.typeography_ui28Bold___Dn4h {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
.typeography_ui28SemiBold__rl85W {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
.typeography_ui28Medium__Z49UY {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.typeography_ui20Bold__SUgYJ {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.typeography_ui20SemiBold__BulCK {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.typeography_ui20Medium__idy4H {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}

.typeography_ui16Bold__zmoXC {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16SemiBold__4AoKd {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16Medium__cbuWD {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16Regular__0dRCl {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}

.typeography_ui14Bold__ESeJ5 {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}
.typeography_ui14SemiBold__T0jgm {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
}
.typeography_ui14Medium__LuJ8w {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
}
.typeography_ui14Regular__RU8O4 {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.typeography_ui12SemiBold__3xcry {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}
.typeography_ui12Medium__Jda7a {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}
.typeography_ui12Regular__R0d9s {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}

.typeography_ui11SemiBold__QKYkF {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui11Medium__eulDk {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui11Regular__dSzmA {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui10Regular__DxFxW {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.04px;
}

.Toast_toast__8tgM1 {
  --toastInfoColor: var(--colorGray8);
  --toastInfoBackgroundColor: white;
  --toastInfoIconColor: var(--colorBlue8);
  --toastInfoBarColor: var(--colorBlue8);

  --toastErrorColor: var(--colorGray8);
  --toastErrorBackgroundColor: white;
  --toastErrorIconColor: var(--colorRed8);
  --toastErrorBarColor: var(--colorRed8);

  --toastSuccessColor: var(--colorGray8);
  --toastSuccessBackgroundColor: white;
  --toastSuccessIconColor: var(--colorGreen8);
  --toastSuccessBarColor: var(--colorGreen8);

  --toastWarningColor: var(--colorGray8);
  --toastWarningBackgroundColor: white;
  --toastWarningIconColor: var(--colorYellow8);
  --toastWarningBarColor: var(--colorYellow7);
}

.Toast_info__eA_RT,
.Toast_warning__ltuuH,
.Toast_error__J24pO,
.Toast_success__7nbc0 {
}

.Toast_toastTitleInfo__3H1GP,
.Toast_toastTitleWarning__bcfgv,
.Toast_toastTitleSuccess__RfC8T,
.Toast_toastTitleError__odPlL {
}

.Toast_toast__8tgM1 {
  padding: 12px 16px;
  color: var(--toastInfoColor);
  background-color: var(--toastInfoBackgroundColor);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.18);
  max-width: 320px;
  border-left: 6px solid var(--toastInfoBarColor);
}

.Toast_toastInner__gYuEF {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.Toast_toastIcon__ByQ0O {
  flex-shrink: 0;
  margin-top: 2px;
  color: var(--toastInfoIconColor);
  margin-right: 1rem;
}

.Toast_toastRight__pS3mx {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Toast_condensed__Sfn6i .Toast_toastRight__pS3mx {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Toast_toastTitle__Lhmq5 {
  color: var(--colorBlack2);
}

.Toast_condensed__Sfn6i .Toast_toastTitle__Lhmq5 {
  margin-right: 12px;
}

.Toast_dismissable__bLQOZ .Toast_toastMessage__H9U4X {
  max-width: 235px;
}

.Toast_toastMessage__H9U4X {
  text-align: left;
}

.Toast_toastMessage__H9U4X h4,
.Toast_toastMessage__H9U4X p {
  margin-bottom: 0.5rem;
}

.Toast_toastMessage__H9U4X small {
  display: block;
  line-height: inherit;
  margin-bottom: 0.5rem;
}

.Toast_toast__8tgM1.Toast_error__J24pO {
  color: var(--toastErrorColor);
  background-color: var(--toastErrorBackgroundColor);
  border-left: 6px solid var(--toastErrorBarColor);
}

.Toast_toast__8tgM1.Toast_error__J24pO .Toast_toastIcon__ByQ0O {
  color: var(--toastErrorIconColor);
}

.Toast_toast__8tgM1.Toast_success__7nbc0 {
  color: var(--toastSuccessColor);
  background-color: var(--toastSuccessBackgroundColor);
  border-left: 6px solid var(--toastSuccessBarColor);
}

.Toast_toast__8tgM1.Toast_success__7nbc0 .Toast_toastIcon__ByQ0O {
  color: var(--toastSuccessIconColor);
}

.Toast_toast__8tgM1.Toast_warning__ltuuH {
  color: var(--toastWarningColor);
  background-color: var(--toastWarningBackgroundColor);
  border-left: 6px solid var(--toastWarningBarColor);
}

.Toast_toast__8tgM1.Toast_warning__ltuuH .Toast_toastIcon__ByQ0O {
  color: var(--toastWarningIconColor);
}

.Toast_dismiss__xlqOg {
  cursor: pointer;
  width: 10px;
}

.ToastContext_globalToastWrapper__MW0D_ {
  position: fixed;
  display: flex;
  justify-content: right;
  z-index: 100;
  top: calc(64px + 32px);
  right: 32px;
}

@keyframes ProgressSpinner_spinner__uIUMN {
  0%,
  100% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
}

.ProgressSpinner_progressSpinner__nPi0c circle {
  animation-name: ProgressSpinner_spinner__uIUMN;
  animation-duration: 680ms;
  animation-iteration-count: infinite;
  opacity: 0.1;
}

.ProgressSpinner_progressSpinner__nPi0c circle:nth-child(1) {
  animation-delay: 80ms;
}

.ProgressSpinner_progressSpinner__nPi0c circle:nth-child(2) {
  animation-delay: 160ms;
}

.ProgressSpinner_progressSpinner__nPi0c circle:nth-child(3) {
  animation-delay: 240ms;
}

.Button_button__L2wUb {
  /* Default */
  --colorButtonDefaultText: var(--colorBlack2);
  --colorButtonDefaultBackground: var(--colorGray2);
  --colorButtonDefaultBorder: transparent;
  --colorButtonDefaultHoverText: white;
  --colorButtonDefaultHoverBackground: var(--colorGray11);
  --colorButtonDefaultActiveText: white;
  --colorButtonDefaultActiveBackground: var(--colorGray12);
  --colorButtonDefaultDisabledText: var(--colorGray4);
  --colorButtonDefaultDisabledBackground: var(--colorGray1);
  --colorButtonDefaultSubmittingText: var(--colorGray12);
  --colorButtonDefaultSubmittingBackground: var(--colorGray1);

  /* Brand / Tan */
  --colorButtonBrandText: var(--colorBlack2);
  --colorButtonBrandBackground: var(--colorTan2);
  --colorButtonBrandBorder: transparent;
  --colorButtonBrandHoverText: white;
  --colorButtonBrandHoverBackground: var(--colorTan11);
  --colorButtonBrandActiveText: white;
  --colorButtonBrandActiveBackground: var(--colorTan12);
  --colorButtonBrandDisabledText: var(--colorTan4);
  --colorButtonBrandDisabledBackground: var(--colorTan1);
  --colorButtonBrandSubmittingText: var(--colorTan12);
  --colorButtonBrandSubmittingBackground: var(--colorTan1);

  /* Transparent */
  --colorButtonTransparentText: var(--colorGray10);
  --colorButtonTransparentBackground: transparent;
  --colorButtonTransparentHoverText: white;
  --colorButtonTransparentHoverBackground: var(--colorGray11);
  --colorButtonTransparentActiveText: white;
  --colorButtonTransparentActiveBackground: var(--colorGray12);
  --colorButtonTransparentDisabledText: var(--colorGray4);
  --colorButtonTransparentDisabledBackground: var(--colorGray1);
  --colorButtonTransparentSubmittingText: var(--colorGray11);
  --colorButtonTransparentSubmittingBackground: var(--colorGray1);

  /* Primary */
  --colorButtonPrimaryText: white;
  --colorButtonPrimaryBackground: var(--colorBlue8);
  --colorButtonPrimaryHoverText: white;
  --colorButtonPrimaryHoverBackground: var(--colorBlue9);
  --colorButtonPrimaryActiveText: white;
  --colorButtonPrimaryActiveBackground: var(--colorBlue10);
  --colorButtonPrimaryDisabledText: var(--colorBlue3);
  --colorButtonPrimaryDisabledBackground: var(--colorBlue5);
  --colorButtonPrimarySubmittingText: white;
  --colorButtonPrimarySubmittingBackground: var(--colorBlue6);

  /* Link */
  --colorButtonLinkText: var(--colorBlue8);
  --colorButtonLinkBackground: white;
  --colorButtonLinkHoverText: var(--colorBlue9);
  --colorButtonLinkHoverBackground: white;
  --colorButtonLinkActiveText: var(--colorBlue9);
  --colorButtonLinkActiveBackground: var(--colorBlue1);
  --colorButtonLinkDisabledText: var(--colorGray4);
  --colorButtonLinkDisabledBackground: white;
  --colorButtonLinkSubmittingText: var(--colorBlue9);
  --colorButtonLinkSubmittingBackground: white;

  /* Positive */
  --colorButtonPositiveText: white;
  --colorButtonPositiveBackground: var(--colorGreen8);
  --colorButtonPositiveHoverText: white;
  --colorButtonPositiveHoverBackground: var(--colorGreen9);
  --colorButtonPositiveActiveText: white;
  --colorButtonPositiveActiveBackground: var(--colorGreen10);
  --colorButtonPositiveDisabledText: var(--colorGreen3);
  --colorButtonPositiveDisabledBackground: var(--colorGreen6);
  --colorButtonPositiveSubmittingText: white;
  --colorButtonPositiveSubmittingBackground: var(--colorGreen6);

  /* Reverse */
  --colorButtonReverseText: var(--colorBlack2);
  --colorButtonReverseBackground: rgba(255, 255, 255, 0.97);
  --colorButtonReverseHoverText: var(--colorBlack2);
  --colorButtonReverseHoverBackground: rgba(255, 255, 255, 1);
  --colorButtonReverseActiveText: var(--colorBlack2);
  --colorButtonReverseActiveBackground: rgba(255, 255, 255, 1);
  --colorButtonReverseDisabledText: var(--colorGray5);
  --colorButtonReverseDisabledBackground: rgba(255, 255, 255, 0.7);
  --colorButtonReverseSubmittingText: var(--colorBlack2);
  --colorButtonReverseSubmittingBackground: rgba(255, 255, 255, 0.7);

  /* Dark */
  --colorButtonDarkText: white;
  --colorButtonDarkBackground: var(--colorGray10);
  --colorButtonDarkHoverText: white;
  --colorButtonDarkHoverBackground: var(--colorGray11);
  --colorButtonDarkActiveText: white;
  --colorButtonDarkActiveBackground: var(--colorGray12);
  --colorButtonDarkDisabledText: var(--colorGray5);
  --colorButtonDarkDisabledBackground: var(--colorGray8);
  --colorButtonDarkSubmittingText: white;
  --colorButtonDarkSubmittingBackground: var(--colorGray8);

  /* Light */
  --colorButtonLightText: var(--colorBlack2);
  --colorButtonLightBackground: white;
  --colorButtonLightHoverText: var(--colorBlack2);
  --colorButtonLightHoverBackground: var(--colorGray1);
  --colorButtonLightActiveText: var(--colorBlack2);
  --colorButtonLightActiveBackground: var(--colorGray2);
  --colorButtonLightDisabledText: var(--colorGray5);
  --colorButtonLightDisabledBackground: white;
  --colorButtonLightSubmittingText: var(--colorBlack2);
  --colorButtonLightSubmittingBackground: white;

  /* Danger */
  --colorButtonDangerText: white;
  --colorButtonDangerBackground: var(--colorRed8);
  --colorButtonDangerHoverBackground: var(--sh_colorGray5);
  --colorButtonDangerHoverText: white;
  --colorButtonDangerHoverBackground: var(--colorRed9);
  --colorButtonDangerActiveText: white;
  --colorButtonDangerActiveBackground: var(--colorRed10);
  --colorButtonDangerDisabledText: var(--colorRed4);
  --colorButtonDangerDisabledBackground: var(--colorRed6);
  --colorButtonDangerSubmittingText: white;
  --colorButtonDangerSubmittingBackground: var(--colorRed6);

  /* Warning */
  --colorButtonWarningText: white;
  --colorButtonWarningBackground: var(--colorYellow7);
  --colorButtonWarningHoverBackground: var(--sh_colorGray5);
  --colorButtonWarningHoverText: white;
  --colorButtonWarningHoverBackground: var(--colorYellow8);
  --colorButtonWarningActiveText: white;
  --colorButtonWarningActiveBackground: var(--colorYellow9);
  --colorButtonWarningDisabledText: var(--sh_colorGray4);
  --colorButtonWarningDisabledBackground: var(--colorYellow6);
  --colorButtonWarningSubmittingText: white;
  --colorButtonWarningSubmittingBackground: var(--colorYellow6);

  /* Danger Secondary */
  --colorButtonDangerSecondaryText: var(--colorRed8);
  --colorButtonDangerSecondaryBackground: var(--sh_colorGray2);
  --colorButtonDangerSecondaryHoverText: white;
  --colorButtonDangerSecondaryHoverBackground: var(--colorRed9);
  --colorButtonDangerSecondaryActiveText: white;
  --colorButtonDangerSecondaryActiveBackground: var(--colorRed10);
  --colorButtonDangerSecondaryDisabledText: var(--sh_colorGray4);
  --colorButtonDangerSecondaryDisabledBackground: var(--sh_colorGray1);
  --colorButtonDangerSecondarySubmittingText: white;
  --colorButtonDangerSecondarySubmittingBackground: var(--colorRed6);

  /* Positive Secondary */
  --colorButtonPositiveSecondaryText: var(--colorGreen8);
  --colorButtonPositiveSecondaryBackground: var(--sh_colorGray2);
  --colorButtonPositiveSecondaryHoverText: white;
  --colorButtonPositiveSecondaryHoverBackground: var(--colorGreen9);
  --colorButtonPositiveSecondaryActiveText: white;
  --colorButtonPositiveSecondaryActiveBackground: var(--colorGreen10);
  --colorButtonPositiveSecondaryDisabledText: var(--sh_colorGray4);
  --colorButtonPositiveSecondaryDisabledBackground: var(--sh_colorGray1);
  --colorButtonPositiveSecondarySubmittingText: white;
  --colorButtonPositiveSecondarySubmittingBackground: var(--colorGreen6);
}

.Button_button__L2wUb {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 32px; /* Overidden by size */
  padding: 6px 16px; /* Overidden by size */

  border: 1px solid var(--colorButtonDefaultBorder); /* Overidden by kind */
  border-radius: 2px; /* Overidden by isJoinedLeft and isJoinedRight */
  box-shadow: none;

  color: var(--colorButtonDefaultText);
  background-color: var(--colorButtonDefaultBackground);

  text-align: center;
  text-decoration: none;

  transition-property: opacity, color, background-color, border-color, box-shadow, transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  cursor: pointer;
}

.Button_button__L2wUb:hover {
  /* Ensures anchor tags remain default text color */
  color: var(--colorButtonDefaultHoverText);
  background-color: var(--colorButtonDefaultHoverBackground);
}

.Button_button__L2wUb:focus {
  box-shadow: 0 0 0 4px var(--colorInputFocusOutline);
  outline: 0 none;
}

.Button_button__L2wUb:disabled,
.Button_button__L2wUb.Button_disabled__2oRNk {
  pointer-events: none;
  cursor: default;
  color: var(--colorButtonDefaultDisabledText);
  background-color: var(--colorButtonDefaultDisabledBackground);
}

.Button_button__L2wUb:active,
.Button_button__L2wUb.Button_active__35Uoc {
  color: var(--colorButtonDefaultActiveText);
  background-color: var(--colorButtonDefaultActiveBackground);
}

.Button_button__L2wUb.Button_isSubmitting__G83ul {
  pointer-events: none;
  cursor: default;
  color: var(--colorButtonDefaultSubmittingText);
  background-color: var(--colorButtonDefaultSubmittingBackground);
}

/* Size */

.Button_button__L2wUb.Button_mini__uvzS3 {
  height: 1.75rem;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.75rem;
}

.Button_button__L2wUb.Button_large__HDaN_ {
  height: 2.25rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.125rem;
  font-weight: 500;
}

/* Kind */

/* Brand */
.Button_button__L2wUb.Button_brand__bP_NS {
  border: none;
  color: var(--colorButtonBrandText);
  background-color: var(--colorButtonBrandBackground);
}

.Button_button__L2wUb.Button_brand__bP_NS:hover {
  color: var(--colorButtonBrandHoverText);
  background-color: var(--colorButtonBrandHoverBackground);
}

.Button_button__L2wUb.Button_brand__bP_NS:active,
.Button_button__L2wUb.Button_brand__bP_NS.Button_active__35Uoc {
  color: var(--colorButtonBrandActiveText);
  background-color: var(--colorButtonBrandActiveBackground);
}

.Button_button__L2wUb.Button_brand__bP_NS:disabled,
.Button_button__L2wUb.Button_brand__bP_NS.Button_disabled__2oRNk {
  color: var(--colorButtonBrandDisabledText);
  background-color: var(--colorButtonBrandDisabledBackground);
}

.Button_button__L2wUb.Button_brand__bP_NS.Button_isSubmitting__G83ul {
  color: var(--colorButtonBrandSubmittingText);
  background-color: var(--colorButtonBrandSubmittingBackground);
}

/* Dark */
.Button_button__L2wUb.Button_dark__T6Uxe {
  border: none;
  color: var(--colorButtonDarkText);
  background-color: var(--colorButtonDarkBackground);
}

.Button_button__L2wUb.Button_dark__T6Uxe:hover {
  color: var(--colorButtonDarkHoverText);
  background-color: var(--colorButtonDarkHoverBackground);
}

.Button_button__L2wUb.Button_dark__T6Uxe:active,
.Button_button__L2wUb.Button_dark__T6Uxe.Button_active__35Uoc {
  color: var(--colorButtonDarkActiveText);
  background-color: var(--colorButtonDarkActiveBackground);
}

.Button_button__L2wUb.Button_dark__T6Uxe:disabled,
.Button_button__L2wUb.Button_dark__T6Uxe.Button_disabled__2oRNk {
  color: var(--colorButtonDarkDisabledText);
  background-color: var(--colorButtonDarkDisabledBackground);
}

.Button_button__L2wUb.Button_dark__T6Uxe.Button_isSubmitting__G83ul {
  color: var(--colorButtonDarkSubmittingText);
  background-color: var(--colorButtonDarkSubmittingBackground);
}

/* Transparent */
.Button_button__L2wUb.Button_transparent__bxqJO {
  border: none;
  padding: 0 8px;
  color: var(--colorButtonTransparentText);
  background-color: var(--colorButtonTransparentBackground);
}

.Button_button__L2wUb.Button_transparent__bxqJO:hover {
  color: var(--colorButtonTransparentHoverText);
  background-color: var(--colorButtonTransparentHoverBackground);
}

.Button_button__L2wUb.Button_transparent__bxqJO:active,
.Button_button__L2wUb.Button_transparent__bxqJO.Button_active__35Uoc {
  color: var(--colorButtonTransparentActiveText);
  background-color: var(--colorButtonTransparentActiveBackground);
}

.Button_button__L2wUb.Button_transparent__bxqJO:disabled,
.Button_button__L2wUb.Button_transparent__bxqJO.Button_disabled__2oRNk {
  color: var(--colorButtonTransparentDisabledText);
  background-color: var(--colorButtonTransparentDisabledBackground);
}

.Button_button__L2wUb.Button_transparent__bxqJO.Button_isSubmitting__G83ul {
  color: var(--colorButtonTransparentSubmittingText);
  background-color: var(--colorButtonTransparentSubmittingBackground);
}

/* Primary */
.Button_button__L2wUb.Button_primary__wnomA {
  border: none;
  color: var(--colorButtonPrimaryText);
  background-color: var(--colorButtonPrimaryBackground);
}

.Button_button__L2wUb.Button_primary__wnomA:hover {
  color: var(--colorButtonPrimaryHoverText);
  background-color: var(--colorButtonPrimaryHoverBackground);
}

.Button_button__L2wUb.Button_primary__wnomA:active,
.Button_button__L2wUb.Button_primary__wnomA.Button_active__35Uoc {
  color: var(--colorButtonPrimaryActiveText);
  background-color: var(--colorButtonPrimaryActiveBackground);
}

.Button_button__L2wUb.Button_primary__wnomA:disabled,
.Button_button__L2wUb.Button_primary__wnomA.Button_disabled__2oRNk {
  color: var(--colorButtonPrimaryDisabledText);
  background-color: var(--colorButtonPrimaryDisabledBackground);
}

.Button_button__L2wUb.Button_primary__wnomA.Button_isSubmitting__G83ul {
  color: var(--colorButtonPrimarySubmittingText);
  background-color: var(--colorButtonPrimarySubmittingBackground);
}

/* Link */
.Button_button__L2wUb.Button_link___7Gbu {
  border: none;
  color: var(--colorButtonLinkText);
  background-color: var(--colorButtonLinkBackground);
}

.Button_button__L2wUb.Button_link___7Gbu:hover {
  color: var(--colorButtonLinkHoverText);
  background-color: var(--colorButtonLinkHoverBackground);
  text-decoration: underline;
}

.Button_button__L2wUb.Button_link___7Gbu:active,
.Button_button__L2wUb.Button_link___7Gbu.Button_active__35Uoc {
  color: var(--colorButtonLinkActiveText);
  background-color: var(--colorButtonLinkActiveBackground);
  text-decoration: underline;
}

.Button_button__L2wUb.Button_link___7Gbu:disabled,
.Button_button__L2wUb.Button_link___7Gbu.Button_disabled__2oRNk {
  color: var(--colorButtonLinkDisabledText);
  background-color: var(--colorButtonLinkDisabledBackground);
  text-decoration: none;
}

.Button_button__L2wUb.Button_link___7Gbu.Button_isSubmitting__G83ul {
  color: var(--colorButtonLinkSubmittingText);
  background-color: var(--colorButtonLinkSubmittingBackground);
  text-decoration: none;
}

/* Positive */
.Button_button__L2wUb.Button_positive__F2oAi {
  border: none;
  color: var(--colorButtonPositiveText);
  background-color: var(--colorButtonPositiveBackground);
}

.Button_button__L2wUb.Button_positive__F2oAi:hover {
  color: var(--colorButtonPositiveHoverText);
  background-color: var(--colorButtonPositiveHoverBackground);
}

.Button_button__L2wUb.Button_positive__F2oAi:disabled,
.Button_button__L2wUb.Button_positive__F2oAi.Button_disabled__2oRNk {
  color: var(--colorButtonPositiveDisabledText);
  background-color: var(--colorButtonPositiveDisabledBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_positive__F2oAi:active,
.Button_button__L2wUb.Button_button__L2wUb.Button_positive__F2oAi.Button_active__35Uoc {
  color: var(--colorButtonPositiveActiveText);
  background-color: var(--colorButtonPositiveActiveBackground);
}

.Button_button__L2wUb.Button_positive__F2oAi.Button_isSubmitting__G83ul {
  color: var(--colorButtonPositiveSubmittingText);
  background-color: var(--colorButtonPositiveSubmittingBackground);
}

/* Reverse */
.Button_button__L2wUb.Button_reverse__lJZld {
  border: none;
  color: var(--colorButtonReverseText);
  background-color: var(--colorButtonReverseBackground);
  mix-blend-mode: lighten;
}

.Button_button__L2wUb.Button_reverse__lJZld:hover {
  color: var(--colorButtonReverseHoverText);
  background-color: var(--colorButtonReverseHoverBackground);
}

.Button_button__L2wUb.Button_reverse__lJZld:active,
.Button_button__L2wUb.Button_reverse__lJZld.Button_active__35Uoc {
  color: var(--colorButtonReverseActiveText);
  background-color: var(--colorButtonReverseActiveBackground);
}

.Button_button__L2wUb.Button_reverse__lJZld:disabled,
.Button_button__L2wUb.Button_reverse__lJZld.Button_disabled__2oRNk {
  color: var(--colorButtonReverseDisabledText);
  background-color: var(--colorButtonReverseDisabledBackground);
}

.Button_button__L2wUb.Button_reverse__lJZld.Button_isSubmitting__G83ul {
  color: var(--colorButtonReverseSubmittingText);
  background-color: var(--colorButtonReverseSubmittingBackground);
}

/* Light */
.Button_button__L2wUb.Button_light__K0xGz {
  border: 1px solid var(--colorButtonLightText);
  color: var(--colorButtonLightText);
  background-color: var(--colorButtonLightBackground);
}

.Button_button__L2wUb.Button_light__K0xGz:hover {
  color: var(--colorButtonLightHoverText);
  background-color: var(--colorButtonLightHoverBackground);
}

.Button_button__L2wUb.Button_light__K0xGz:active,
.Button_button__L2wUb.Button_light__K0xGz.Button_active__35Uoc {
  color: var(--colorButtonLightActiveText);
  background-color: var(--colorButtonLightActiveBackground);
}

.Button_button__L2wUb.Button_light__K0xGz:disabled,
.Button_button__L2wUb.Button_light__K0xGz.Button_disabled__2oRNk {
  color: var(--colorButtonLightDisabledText);
  background-color: var(--colorButtonLightDisabledBackground);
  border-color: var(--colorButtonLightDisabledText);
}

.Button_button__L2wUb.Button_light__K0xGz.Button_isSubmitting__G83ul {
  color: var(--colorButtonLightSubmittingText);
  background-color: var(--colorButtonLightSubmittingBackground);
}

/* Danger */
.Button_button__L2wUb.Button_danger__iw_Oo {
  border: transparent;
  color: var(--colorButtonDangerText);
  background-color: var(--colorButtonDangerBackground);
}

.Button_button__L2wUb.Button_danger__iw_Oo:hover {
  color: var(--colorButtonDangerHoverText);
  background-color: var(--colorButtonDangerHoverBackground);
}

.Button_button__L2wUb.Button_danger__iw_Oo:disabled,
.Button_button__L2wUb.Button_danger__iw_Oo.Button_disabled__2oRNk {
  color: var(--colorButtonDangerDisabledText);
  background-color: var(--colorButtonDangerDisabledBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_danger__iw_Oo:active,
.Button_button__L2wUb.Button_button__L2wUb.Button_danger__iw_Oo.Button_active__35Uoc {
  color: var(--colorButtonDangerActiveText);
  background-color: var(--colorButtonDangerActiveBackground);
}

.Button_button__L2wUb.Button_danger__iw_Oo.Button_isSubmitting__G83ul {
  color: var(--colorButtonDangerSubmittingText);
  background-color: var(--colorButtonDangerSubmittingBackground);
}

/* Warning */
.Button_button__L2wUb.Button_warning__0GCvw {
  border: transparent;
  color: var(--colorButtonWarningText);
  background-color: var(--colorButtonWarningBackground);
}

.Button_button__L2wUb.Button_warning__0GCvw:hover {
  color: var(--colorButtonWarningHoverText);
  background-color: var(--colorButtonWarningHoverBackground);
}

.Button_button__L2wUb.Button_warning__0GCvw:disabled,
.Button_button__L2wUb.Button_warning__0GCvw.Button_disabled__2oRNk {
  color: var(--colorButtonWarningDisabledText);
  background-color: var(--colorButtonWarningDisabledBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_warning__0GCvw:active,
.Button_button__L2wUb.Button_button__L2wUb.Button_warning__0GCvw.Button_active__35Uoc {
  color: var(--colorButtonWarningActiveText);
  background-color: var(--colorButtonWarningActiveBackground);
}

.Button_button__L2wUb.Button_warning__0GCvw.Button_isSubmitting__G83ul {
  color: var(--colorButtonWarningSubmittingText);
  background-color: var(--colorButtonWarningSubmittingBackground);
}

/* Danger Secondary */
.Button_button__L2wUb.Button_dangerSecondary__zAqPK {
  border: 1px solid transparent;
  color: var(--colorButtonDangerSecondaryText);
  background-color: var(--colorButtonDangerSecondaryBackground);
}

.Button_button__L2wUb.Button_dangerSecondary__zAqPK:hover {
  color: var(--colorButtonDangerSecondaryHoverText);
  background-color: var(--colorButtonDangerSecondaryHoverBackground);
}

.Button_button__L2wUb.Button_dangerSecondary__zAqPK:disabled,
.Button_button__L2wUb.Button_dangerSecondary__zAqPK.Button_disabled__2oRNk {
  color: var(--colorButtonDangerSecondaryDisabledText);
  background-color: var(--colorButtonDangerSecondaryDisabledBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_dangerSecondary__zAqPK:active,
.Button_button__L2wUb.Button_button__L2wUb.Button_dangerSecondary__zAqPK.Button_active__35Uoc {
  color: var(--colorButtonDangerSecondaryActiveText);
  background-color: var(--colorButtonDangerSecondaryActiveBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_dangerSecondary__zAqPK.Button_isSubmitting__G83ul {
  color: var(--colorButtonDangerSecondarySubmittingText);
  background-color: var(--colorButtonDangerSecondarySubmittingBackground);
}

/* Positive Secondary */
.Button_button__L2wUb.Button_positiveSecondary__NKy8B {
  border: 1px solid transparent;
  color: var(--colorButtonPositiveSecondaryText);
  background-color: var(--colorButtonPositiveSecondaryBackground);
}

.Button_button__L2wUb.Button_positiveSecondary__NKy8B:hover {
  color: var(--colorButtonPositiveSecondaryHoverText);
  background-color: var(--colorButtonPositiveSecondaryHoverBackground);
}

.Button_button__L2wUb.Button_positiveSecondary__NKy8B:disabled,
.Button_button__L2wUb.Button_positiveSecondary__NKy8B.Button_disabled__2oRNk {
  color: var(--colorButtonPositiveSecondaryDisabledText);
  background-color: var(--colorButtonPositiveSecondaryDisabledBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_positiveSecondary__NKy8B:active,
.Button_button__L2wUb.Button_button__L2wUb.Button_positiveSecondary__NKy8B.Button_active__35Uoc {
  color: var(--colorButtonPositiveSecondaryActiveText);
  background-color: var(--colorButtonPositiveSecondaryActiveBackground);
}

.Button_button__L2wUb.Button_button__L2wUb.Button_positiveSecondary__NKy8B.Button_isSubmitting__G83ul {
  color: var(--colorButtonPositiveSecondarySubmittingText);
  background-color: var(--colorButtonPositiveSecondarySubmittingBackground);
}

/* Box model / flexbox / shape */

.Button_button__L2wUb.Button_isBlock__IcHbu {
  width: 100%;
}

.Button_button__L2wUb.Button_isElastic__lnBZo {
  flex-grow: 1;
}

.Button_button__L2wUb.Button_isJoinedLeft__7pRiV {
  border-radius: 0 2px 2px 0;
}

.Button_button__L2wUb.Button_isJoinedRight__H5A7W {
  border-radius: 2px 0 0 2px;
}

/* Children */

.Button_buttonInner___aXhU {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button_label__SItMB {
  display: inline-block;
}

/* Icons */

.Button_button__L2wUb.Button_hasIconLeft__VAbi5 {
  padding-left: 12px;
}

.Button_button__L2wUb.Button_hasIconRight__N_kFk {
  padding-right: 12px;
}

.Button_icon__mSIRG {
  display: inline-block;
  margin: 0 0.35rem;
}

.Button_hasIconLeft__VAbi5 .Button_icon__mSIRG {
  margin-left: 0;
}

.Button_hasIconRight__N_kFk .Button_icon__mSIRG {
  margin-right: 0;
}

.Button_button__L2wUb.Button_isIconOnly__6T5HH {
  padding: 8px 12px;
}

.Button_isIconOnly__6T5HH .Button_icon__mSIRG {
  margin: 0;
}

/* Interaction */

.Button_hide__X1gKA {
  visibility: hidden;
}

.Button_hasSubmittingText__glcdl .Button_hide__X1gKA {
  display: none;
}

.Button_progressSpinner__GNvxs {
  position: absolute;
  display: inline-block;
  height: 100%;
}

.Button_hasSubmittingText__glcdl .Button_progressSpinner__GNvxs {
  position: static;
  margin-left: 10px;
}

/* Button Group */

.Button_buttonGroup__1_Fbw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Button_buttonGroup__1_Fbw > a,
.Button_buttonGroup__1_Fbw > button {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.Button_buttonGroup__1_Fbw > a:first-child,
.Button_buttonGroup__1_Fbw > button:first-child {
  margin-left: 0;
}

.Button_buttonGroup__1_Fbw > a:last-child,
.Button_buttonGroup__1_Fbw > button:last-child {
  margin-right: 0;
}

.Button_buttonGroup__1_Fbw.Button_alignRight__HVWtM {
  justify-content: flex-end;
}

.ModalContainer_overlay__t5gig {
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalContainer_content__x7U8D {
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ModalContainer_hideScroll__rGTvC::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.ModalContainer_hideScroll__rGTvC {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ModalContainer_content__x7U8D:focus-visible {
  outline: none;
}

.ModalContainer_bodyOpen__qNLPU {
  overflow: hidden;
}

.ModifySubscriptionModal_modalContainer__njJYt {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: white;
  border-radius: 2px;
  max-width: 598px;
  width: 100%;
}

.ModifySubscriptionModal_modalHeader__wedpX {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.ModifySubscriptionModal_modalTitle__pcCX6 {
  margin-bottom: 0;
}

.ModifySubscriptionModal_modalClose__IxJQ4 {
  color: var(--colorGray7);
  cursor: pointer;
}

.ModifySubscriptionModal_modalBody__Tegfm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 24px 32px 24px;
  gap: 24px;
}

.ModifySubscriptionModal_addonDetails__3k6Gz {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ModifySubscriptionModal_addonDetails__3k6Gz p {
  margin-bottom: 0;
}

.ModifySubscriptionModal_addonTitle__DYWvc,
.ModifySubscriptionModal_addonPrice__ubLxl {
}

.ModifySubscriptionModal_addonPricing___UpJZ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  background-color: var(--colorGray1);
  padding: 8px;
}

.ModifySubscriptionModal_grayedText__v7PdZ {
  color: var(--colorGray6);
}

.ModifySubscriptionModal_modalFooter__alo_b {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-top: 1px solid var(--colorGray3);
  padding: 24px;
  gap: 16px;
}

/* Custom media queries
 * https://github.com/postcss/postcss-custom-media
 * Enabled via custom nextjs postcss config in package.json#postcss
 * https://nextjs.org/docs/advanced-features/customizing-postcss-config#customizing-plugins
 */

/*

SOME NOTES:

We have had 3 different break point systems in use before:

- Original Rails app used Bootstrap 3:

    - 0-767 mobile
    - 768-991 tablet
    - 992-1199 small desktop
    - 1200+ large desktop

    Typically 15 or 30 px is used as padding/margin between elements


- Original Marketplace used:

    - 0-520 mobile
    - 521-740 tablet
    - 741-1024 small desktop
    - 1025+ large desktop

    Typically 16px is used as padding/margin between elements


- New Marketplace/SellerHub breakpoints:

    - 0-767 mobile
    - 768-1023 tablet portrait
    - 1024-1151 tablet landscape
    - 1152-1439 small desktop
    - 1440+ large desktop

    Typically 16px is used as padding/margin between elements


- Seller Hub is typically a max-width 1136 container centered in the page. No need to design for multiple breakpoints

    0-767:     100% max-width container
    768-1023:   768 max-width container
    1024-1151: 1024 max-width container
    1152-1439: 1136 max-width container
    1440+:     1136 max-width container

- Marketplace uses the following max-width containers at each breakpoint

    0-767:     100% max-width container
    768-1023:   640 max-width container
    1024-1151:  864 max-width container
    1152-1439: 1088 max-width container
    1440+:     1312 max-width container


*/

:root {
  --rem: 16px;
  --scrollPosition: 0px;

  /* Base Colors */
  --colorBlue: hsla(218, 100%, 58%, 1);
  --colorBlueDark: hsla(217, 72%, 48%, 1);
  --colorBlueDark2: hsla(218, 72%, 37%, 1);
  --colorBlueFade: rgba(34, 100, 209, 0.1);
  --colorBlue1: hsla(222, 100%, 98%, 1);
  --colorBlue2: hsla(219, 100%, 96%, 1);
  --colorBlue3: hsla(218, 100%, 92%, 1);
  --colorBlue4: #c4daff;
  --colorBlue5: hsla(217, 100%, 81%, 1);
  --colorBlue6: hsla(218, 100%, 73%, 1);
  --colorBlue7: #4f91ff;
  --colorBlue8: hsla(218, 100%, 58%, 1);
  --colorBlue9: hsla(217, 72%, 48%, 1);
  --colorBlue10: hsla(218, 72%, 37%, 1);
  --colorBlue11: #133774;
  --colorBlue12: #0c2146;

  --colorGreen: hsl(83, 94%, 42%);
  --colorGreenDark: hsl(83, 96%, 28%);
  --colorGreenDark2: hsl(172, 7%, 24%);
  --colorGreenDark3: hsl(150, 2%, 13%);
  --colorGreenFade: hsla(83, 90%, 42%, 16%);

  --colorGreen1: hsla(120, 33%, 97%, 1);
  --colorGreen2: #ebf7ed;
  --colorGreen3: hsla(124, 32%, 90%, 1);
  --colorGreen4: #cbe5cc;
  --colorGreen5: #a9d3ab;
  --colorGreen6: hsla(122, 33%, 65%, 1);
  --colorGreen7: hsla(122, 33%, 55%, 1);
  --colorGreen8: hsla(123, 41%, 45%, 1);
  --colorGreen9: hsla(123, 41%, 36%, 1);
  --colorGreen10: hsla(123, 41%, 28%, 1);
  --colorGreen11: #1f4921;
  --colorGreen12: #132c14;

  --colorPurple: hsl(293, 78%, 49%);
  --colorPurple1: #faf5fe;
  --colorPurple2: #f3ecfd;
  --colorPurple3: #e8d9fc;
  --colorPurple4: #dcc6fb;
  --colorPurple5: #c4a1f9;
  --colorPurple6: #ae7cf7;
  --colorPurple7: #9859f6;
  --colorPurple8: #843af5;
  --colorPurple9: #6c30c9;
  --colorPurple10: #54259d;
  --colorPurple11: #3d1a70;
  --colorPurple12: #250f43;
  --colorPurpleDark: hsl(293, 86%, 38%);
  --colorPurpleFade: hsla(293, 69%, 68%, 19%);

  --colorRed: hsl(2, 76%, 51%);
  --colorRed1: #fef2f1;
  --colorRed2: #fee8e7;
  --colorRed3: #fddcda;
  --colorRed4: hsla(3, 90%, 89%, 1);
  --colorRed5: #faa9a3;
  --colorRed6: hsla(4, 90%, 74%, 1);
  --colorRed7: #f6655a;
  --colorRed8: hsla(4, 90%, 58%, 1);
  --colorRed9: hsla(4, 63%, 48%, 1);
  --colorRed10: hsla(4, 63%, 37%, 1);
  --colorRed11: #6f1f19;
  --colorRed12: #43130f;
  --colorRedDark: hsl(2, 82%, 44%);
  --colorRedFade: rgba(200, 55, 45, 0.08);

  --colorOrange: #f49135;

  --colorYellow: hsl(42, 95%, 55%);
  --colorYellow1: #fff8eb;
  --colorYellow2: #ffefd1;
  --colorYellow3: #ffe5b3;
  --colorYellow4: #ffd98f;
  --colorYellow5: #f5ce84;
  --colorYellow6: #ebbf67;
  --colorYellow7: #e5ae40;
  --colorYellow8: #d6981b;
  --colorYellow9: #b88217;
  --colorYellow10: #8f6512;
  --colorYellow11: #66480d;
  --colorYellow12: #463209;
  --colorYellowDark: hsl(42, 95%, 39%);
  --colorYellowFade: hsla(42, 95%, 55%, 15%);

  --colorWhite: white;

  --colorBlack: hsl(0, 0%, 13%);
  --colorBlack2: hsl(0, 0%, 0%);
  --colorBlack3: hsl(173, 7%, 24%);
  --colorBlackDark: hsl(0, 0%, 3%);
  --colorBlackFade: rgba(0, 0, 0, 0.08);

  --colorGray: hsl(207, 5%, 84%);
  --colorGray0: #fafafa;
  --colorGray1: #f7f7f7;
  --colorGray2: #efefef;
  --colorGray3: #e2e2e2;
  --colorGray4: #d4d4d4;
  --colorGray5: #b7b7b7;
  --colorGray6: #9b9b9b;
  --colorGray7: #7f7f7f;
  --colorGray8: #626262;
  --colorGray9: #515151;
  --colorGray10: #3f3f3f;
  --colorGray11: #2d2d2d;
  --colorGray12: #1b1b1b;
  --colorDarkGray: hsl(0, 0%, 25%);

  --colorTan1: #fbfaf9;
  --colorTan2: #f4f3f1;
  --colorTan3: #edece8;
  --colorTan4: #e4e2dd;
  --colorTan5: #d2cfc6;
  --colorTan6: #c0bbaf;
  --colorTan7: #aea898;
  --colorTan8: #9f9784;
  --colorTan9: #837b67;
  --colorTan10: #676151;
  --colorTan11: #4a463a;
  --colorTan12: #2e2b24;

  --colorSuna: hsl(42, 43%, 49%);
  --colorGold: #b39b47;

  /* These are old tan colors, not really consistent with new tan colors. Don't use unless you need to... */
  --colorOldTan1: #fbfbfa;
  --colorOldTan2: #f7f5f2;
  --colorOldTan3: #837d6a;
  --colorOldTan4: #edece8;

  --colorSpotlightBackground: hsla(36, 24%, 96%, 1);

  /* Brand Color */
  --colorPrimary: var(--colorBlue);
  --colorPrimaryDark: var(--colorBlueDark);
  --colorPrimaryDark2: var(--colorBlueDark2);
  --colorPrimaryFade: var(--colorBlueFade);

  /* Element Color */
  --colorAnchorText: var(--colorPrimary);
  --colorAnchorTextHover: var(--colorPrimaryDark);

  --colorButtonDefault: var(--colorGreenDark2);

  --colorBadgeDefaultText: var(--colorTextDefault);
  --colorBadgeDefaultBackground: hsl(0, 0%, 92%);

  --colorBorderDefault: var(--colorGray3);
  --colorBorderMuted: hsl(0, 0%, 90%);
  --colorBorderVeryMuted: hsl(0, 0%, 92%);

  --colorDropdownItemHoverBackground: rgba(0, 0, 0, 0.05);
  --colorDropdownItemSelectedBackground: hsl(0, 0%, 97%);

  --colorFieldTitle: var(--colorGray8);
  --colorFieldDisabled: var(--colorGray9);

  --colorFooterForeground: hsl(250, 5%, 60%);
  --colorFooterBackground: hsl(250, 14%, 12%);

  --colorHelpIconDefault: hsl(0, 0%, 85%);
  --colorHelpIconHover: var(--colorPrimary);

  --colorProductSidebarItemSelectedBackground: hsl(0, 0%, 97%);
  --colorProductSidebarItemHoverBackground: hsl(0, 0%, 98%);

  --colorPanelBackgroundGrey: hsl(0, 0%, 98%);
  --colorPanelBackgroundWarm: hsl(40, 38%, 98%);
  --colorPanelBackgroundBrown: hsla(36, 24%, 96%, 1);

  --colorImagePlaceholderIcon: hsl(0, 0%, 90%);

  --colorInputFocusBorder: var(--colorBlue7);
  --colorInputFocusOutline: var(--colorPrimaryFade);
  --colorInputPlaceholder: var(--colorGray6);
  --colorInputDisabled: var(--colorGray9);

  --colorModalBodyBackground: hsl(0, 0%, 98%);

  --colorPageBackgroundDefault: white;
  --colorPageBackgroundLightGrey: hsl(0, 0%, 98%);

  --colorProgressTrack: hsl(0, 0%, 0%, 0.04);
  --colorProgressBar: var(--colorGreen6);

  --colorProgressSpinnerHighlight: hsl(0, 0%, 20%);

  --colorTextDefault: var(--colorBlack2);
  --colorTextMuted: hsl(0, 0%, 60%);
  --colorTextSemiMuted: hsl(0, 0%, 43%);
  --colorTextVeryMuted: hsl(0, 0%, 77%);

  /* Typography */
  --fontFamilySerif: 'Canela Deck Web', georgia, serif;
  --fontFamilySans: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  --fontFamilyMono: 'SF Mono', Menlo, Courier, monospace;
  --priceLabelFontFeatures: 'tnum', 'ss01';

  /* Arbitrary layout sizes */
  --breadcrumbsHeight: 2.5rem;
  --catalogCoverWidth: 13.5rem;
  --elementBorderRadius: 0.125rem;
  --headerNavHeight: 5rem;
  --previewBannerHeight: 42px;
  --headerNavHeightTotal: var(
    --headerNavHeight
  ); /* this is the total height, inclusive of preview banner (if applicable) */
  --alertBannerHeight: 52px;
  --departmentNavHeight: 4rem;
  --approxCatalogHeaderWrapperHeight: 300px;
  --catalogHeaderWrapperHeight: 11.75rem;
  --tablePadding: 0.75rem;

  /* Catalog Layout */
  --catalogGridItemMinWidth: 11rem;
  --catalogGridGap: 2rem;
  --catalogGridMaxWidth: calc(5 * var(--catalogGridItemMinWidth) + 6 * var(--catalogGridGap));

  /* Seller Hub */

  --sh_colorGrayBorder: hsla(240, 3%, 89%, 1);

  --sh_colorBlack: hsl(251, 14%, 9%);
  --sh_colorGrayLight: hsl(116, 0%, 95%);
  --sh_colorGrayDark: hsl(251, 15%, 16%);
  --sh_containerLg: 1136px;
  --sh_borderRadius: 4px;

  --sh_colorGreen5: hsla(127, 44%, 95%, 1);
  --sh_colorGreen7: hsla(122, 33%, 55%, 1);
  --sh_colorGreen60: hsla(123, 41%, 45%, 1);

  --sh_colorRed0: hsla(3, 86%, 97%, 1);
  --sh_colorRed60: hsla(4, 90%, 58%, 1);

  --sh_colorOrange5: hsla(31, 100%, 94%, 1);
  --sh_colorOrange60: hsla(31, 100%, 49%, 1);

  --sh_colorYellow5: hsla(39, 100%, 91%, 1);
  --sh_colorYellow60: hsla(40, 78%, 47%, 1);

  --sh_colorBlue5: hsla(219, 100%, 96%, 1);
  --sh_colorBlue6: hsla(218, 100%, 73%, 1);
  --sh_colorBlue9: hsla(217, 72%, 48%, 1);
  --sh_colorBlue60: hsla(218, 100%, 58%, 1);

  --sh_colorGray1: hsla(0, 0%, 97%, 1);
  --sh_colorGray2: hsla(0, 0%, 94%, 1);
  --sh_colorGray5: hsla(240, 9%, 94%, 1);
  --sh_colorGray4: hsla(0, 0%, 83%, 1);
  --sh_colorGray6: hsla(240, 5%, 50%, 1);
  --sh_colorGray7: hsla(0, 0%, 50%, 1);
  --sh_colorGray8: hsla(0, 0%, 38%, 1);
  --sh_colorGray10: hsla(0, 0%, 25%, 1);
  --sh_colorGray30: hsla(240, 5%, 72%, 1);
  --sh_colorGray40: hsla(240, 5%, 61%, 1);
  --sh_colorGray60: hsla(236, 8%, 38%, 1);
}

:root {
  /* Colors */
  --colorPanelBackgroundBrown: hsla(36, 24%, 96%, 1);
  --colorPageBackgroundDefault: white;

  /* Typography */
  --fontFamilySerif: 'Canela Deck Web', georgia, serif;
  --fontFamilySans: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;

  /* Arbitrary layout sizes */
  --elementBorderRadius: 0.25rem;
}

/* react-datepicker is given the custom class name 'datePickerOverrides'
 * in /components/DatePicker.tsx
 *
 * Not ideal to have the style overriddes included globally,
 * but react-datepicker is pretty lame when it comes to customising css.
 */

/* The 'wrapper' is the input container */
.datePickerWrapper {
  display: block;
  width: 100%;
}

.datePickerWrapper .react-datepicker__close-icon {
  top: 50%;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.75rem;
}

.datePickerWrapper .react-datepicker__close-icon:after {
  display: block;
  content: '×';
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1.25rem; /* This is essentially a baseline shift of 0.25rem */
  font-size: 1.5rem;
  color: var(--colorTextVeryMuted);
  background-color: transparent;
}

/* The 'popper' is the datepicker container */

.datePickerPopper .react-datepicker {
  font-family: var(--fontFamilySans);
  border: 0 none;
  box-shadow: 0 1rem 4.5rem rgba(0, 0, 0, 0.35);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid #eaeaea;
}

.datePickerPopper .react-datepicker__input-container {
  display: block;
}

.datePickerPopper .react-datepicker__day-name {
  color: var(--colorTextMuted);
}

.datePickerPopper .react-datepicker__day--selected,
.datePickerPopper .react-datepicker__day--selected:hover,
.datePickerPopper .react-datepicker__day--keyboard-selected,
.datePickerPopper .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--colorBlue);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__day--outside-month {
  color: var(--colorTextVeryMuted);
}

.datePickerPopper .react-datepicker__triangle {
  display: none;
}


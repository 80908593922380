.toast {
  --toastInfoColor: var(--colorGray8);
  --toastInfoBackgroundColor: white;
  --toastInfoIconColor: var(--colorBlue8);
  --toastInfoBarColor: var(--colorBlue8);

  --toastErrorColor: var(--colorGray8);
  --toastErrorBackgroundColor: white;
  --toastErrorIconColor: var(--colorRed8);
  --toastErrorBarColor: var(--colorRed8);

  --toastSuccessColor: var(--colorGray8);
  --toastSuccessBackgroundColor: white;
  --toastSuccessIconColor: var(--colorGreen8);
  --toastSuccessBarColor: var(--colorGreen8);

  --toastWarningColor: var(--colorGray8);
  --toastWarningBackgroundColor: white;
  --toastWarningIconColor: var(--colorYellow8);
  --toastWarningBarColor: var(--colorYellow7);
}

.info,
.warning,
.error,
.success {
  composes: ui12Regular from '../../styles/typeography.css';
}

.toastTitleInfo,
.toastTitleWarning,
.toastTitleSuccess,
.toastTitleError {
  composes: ui12SemiBold from '../../styles/typeography.css';
}

.toast {
  padding: 12px 16px;
  color: var(--toastInfoColor);
  background-color: var(--toastInfoBackgroundColor);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.18);
  max-width: 320px;
  border-left: 6px solid var(--toastInfoBarColor);
}

.toastInner {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.toastIcon {
  flex-shrink: 0;
  margin-top: 2px;
  color: var(--toastInfoIconColor);
  margin-right: 1rem;
}

.toastRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.condensed .toastRight {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.toastTitle {
  color: var(--colorBlack2);
}

.condensed .toastTitle {
  margin-right: 12px;
}

.dismissable .toastMessage {
  max-width: 235px;
}

.toastMessage {
  text-align: left;
}

.toastMessage h4,
.toastMessage p {
  margin-bottom: 0.5rem;
}

.toastMessage small {
  display: block;
  line-height: inherit;
  margin-bottom: 0.5rem;
}

.toast.error {
  color: var(--toastErrorColor);
  background-color: var(--toastErrorBackgroundColor);
  border-left: 6px solid var(--toastErrorBarColor);
}

.toast.error .toastIcon {
  color: var(--toastErrorIconColor);
}

.toast.success {
  color: var(--toastSuccessColor);
  background-color: var(--toastSuccessBackgroundColor);
  border-left: 6px solid var(--toastSuccessBarColor);
}

.toast.success .toastIcon {
  color: var(--toastSuccessIconColor);
}

.toast.warning {
  color: var(--toastWarningColor);
  background-color: var(--toastWarningBackgroundColor);
  border-left: 6px solid var(--toastWarningBarColor);
}

.toast.warning .toastIcon {
  color: var(--toastWarningIconColor);
}

.dismiss {
  cursor: pointer;
  width: 10px;
}

/* react-datepicker is given the custom class name 'datePickerOverrides'
 * in /components/DatePicker.tsx
 *
 * Not ideal to have the style overriddes included globally,
 * but react-datepicker is pretty lame when it comes to customising css.
 */

/* The 'wrapper' is the input container */
.datePickerWrapper {
  display: block;
  width: 100%;
}

.datePickerWrapper .react-datepicker__close-icon {
  top: 50%;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.75rem;
}

.datePickerWrapper .react-datepicker__close-icon:after {
  display: block;
  content: '×';
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1.25rem; /* This is essentially a baseline shift of 0.25rem */
  font-size: 1.5rem;
  color: var(--colorTextVeryMuted);
  background-color: transparent;
}

/* The 'popper' is the datepicker container */

.datePickerPopper .react-datepicker {
  font-family: var(--fontFamilySans);
  border: 0 none;
  box-shadow: 0 1rem 4.5rem rgba(0, 0, 0, 0.35);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid #eaeaea;
}

.datePickerPopper .react-datepicker__input-container {
  display: block;
}

.datePickerPopper .react-datepicker__day-name {
  color: var(--colorTextMuted);
}

.datePickerPopper .react-datepicker__day--selected,
.datePickerPopper .react-datepicker__day--selected:hover,
.datePickerPopper .react-datepicker__day--keyboard-selected,
.datePickerPopper .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--colorBlue);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__day--outside-month {
  color: var(--colorTextVeryMuted);
}

.datePickerPopper .react-datepicker__triangle {
  display: none;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: white;
  border-radius: 2px;
  max-width: 598px;
  width: 100%;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.modalTitle {
  composes: ui16Medium from 'styles/typeography.css';
  margin-bottom: 0;
}

.modalClose {
  color: var(--colorGray7);
  cursor: pointer;
}

.modalBody {
  composes: ui14Regular from 'styles/typeography.css';
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 24px 32px 24px;
  gap: 24px;
}

.addonDetails {
  composes: ui14Regular from 'styles/typeography.css';
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.addonDetails p {
  margin-bottom: 0;
}

.addonTitle,
.addonPrice {
  composes: ui14SemiBold from 'styles/typeography.css';
}

.addonPricing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  background-color: var(--colorGray1);
  padding: 8px;
}

.grayedText {
  composes: ui11Regular from 'styles/typeography.css';
  color: var(--colorGray6);
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border-top: 1px solid var(--colorGray3);
  padding: 24px;
  gap: 16px;
}
